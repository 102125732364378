import './data-capture.css'
import { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { useGlobalContext } from '../../global-context';
import { SlCloudUpload } from "react-icons/sl";
import { FaRegCheckCircle } from "react-icons/fa";
import {FileRejection} from 'react-dropzone'
import Dropzone from 'react-dropzone'
import { useNavigate, Link } from 'react-router-dom';
import { FaFileZipper } from "react-icons/fa6";

interface JsonFile {
  path: string;
  content: any;
}

export default function DataCapture () {
    const navigate = useNavigate();
    const {variableData, setVariableData, setZipFile, zipFile, isProcessedFile, setIsProcessedFile} = useGlobalContext();

    // const [zipFile, setZipFile] = useState<File | null>(null);
    const [jsonFiles, setJsonFiles] = useState<JsonFile[]>([]);

    // Maneja la carga del archivo zip
    const handleFileUpload = async (file: File) => {
        if (file) {
            setZipFile(file);
        }
    };

    useEffect(() => {
        if (zipFile && (typeof variableData == 'string')) {
            handleProcessZip();
        }
    }, [zipFile]); // Se ejecuta cada vez que `zipFile` cambia

    // Procesa los archivos JSON dentro del zip
    const handleProcessZip = async () => {
        if (!zipFile) return;

        const reader = new FileReader();

        reader.onload = async (e: ProgressEvent<FileReader>) => {
        const arrayBuffer = e.target?.result;
        if (arrayBuffer) {
            const zip = new JSZip();
            const zipContents = await zip.loadAsync(arrayBuffer);
            const jsonFilesData: JsonFile[] = [];

            // Promesas de archivos JSON
            const promises: Promise<void>[] = [];

            // Recorre todos los archivos dentro del zip
            zip.forEach((relativePath, file) => {
            if (file.name.endsWith(".json")) {
                const promise = file.async("string").then((fileContent) => {
                jsonFilesData.push({
                    path: relativePath,
                    content: JSON.parse(fileContent),
                });
                });
                promises.push(promise);
            }
            });

            // Espera que todas las promesas se resuelvan antes de actualizar el estado
            await Promise.all(promises);

            setJsonFiles(jsonFilesData);
            

            // Ordenamiento de los datos

            const dateOptions: Intl.DateTimeFormatOptions = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            };

            let analyzedData: any = {
                following: [],
                followers: [],
                unfollowers: [],
                pendingFollowRequests: [],
                recentlyUnfollowedAccounts: [],
                removedSuggestions: []
            };

            if (jsonFilesData.length > 0) {
                for (let category of jsonFilesData) {
                    if (category.path.endsWith('following.json')) {
                        analyzedData.following = category.content.relationships_following
                            .flatMap((item: any) => item.string_list_data
                            .map((d: any) => ({username: d.value, date: new Date(d.timestamp * 1000).toLocaleString('es-ES', dateOptions), link: d.href})));
                    } 
                    else if (category.path.endsWith('followers_1.json')) {
                        analyzedData.followers = category.content
                            .flatMap((item: any) => item.string_list_data
                            .map((d: any) => ({username: d.value, date: new Date(d.timestamp * 1000).toLocaleString('es-ES', dateOptions), link: d.href})));
                    } 
                    else if (category.path.endsWith('pending_follow_requests.json')) {
                        analyzedData.pendingFollowRequests = category.content.relationships_follow_requests_sent
                            .flatMap((item: any) => item.string_list_data
                            .map((d: any) => ({username: d.value, date: new Date(d.timestamp * 1000).toLocaleString('es-ES', dateOptions), link: d.href})));
                    }
                    else if (category.path.endsWith('recently_unfollowed_profiles.json')) {
                        analyzedData.recentlyUnfollowedAccounts = category.content.relationships_unfollowed_users
                            .flatMap((item: any) => item.string_list_data
                            .map((d: any) => ({username: d.value, date: new Date(d.timestamp * 1000).toLocaleString('es-ES', dateOptions), link: d.href})));
                    }
                    else if (category.path.endsWith('removed_suggestions.json')) {
                        analyzedData.removedSuggestions = category.content.relationships_dismissed_suggested_users
                            .flatMap((item: any) => item.string_list_data
                            .map((d: any) => ({username: d.value, date: new Date(d.timestamp * 1000).toLocaleString('es-ES', dateOptions), link: d.href})));
                    }
                }
        
                const followersUsernames = new Set(analyzedData.followers.map((user: any) => user.username));
                analyzedData.unfollowers = analyzedData.following.filter((user: any) => !followersUsernames.has(user.username));
        
            }

            setVariableData(analyzedData);
            setIsProcessedFile(true);
            console.log('FINISHED ONE TIME');
        }
        };

        reader.readAsArrayBuffer(zipFile);
    };

    const alertedErrors = new Set<string>();

    const onDropRejected = (rejectedFiles: FileRejection[]) => {
        rejectedFiles.forEach((file) => {
            file.errors.forEach((error) => {
                // Si ya mostramos una alerta para este tipo de error, no mostrarla nuevamente
                if (error.code === 'file-invalid-type' && !alertedErrors.has('file-invalid-type')) {
                    alertedErrors.add('file-invalid-type');
                    alert('¡Solo se permiten archivos .zip!');
                } else if (error.code === 'too-many-files' && !alertedErrors.has('too-many-files')) {
                    alertedErrors.add('too-many-files');
                    alert('¡Solo puedes subir un archivo!');
                }
            });
        });
    };

    const onDrop = (file: any) => {
        if (file[0]) {
            handleFileUpload(file[0]);
        }
    }

    const restart = () => {
        setZipFile(null);
        setIsProcessedFile(false);
        setVariableData('restarted');
    }

    return (
        <div className='container2'>
            <p>
                <h2 className='fileTitle'>
                    Cargar archivo zip
                </h2>
                <div className='customParagraph'>
                    Si aún no has descargado tu archivo de instagram, puedes ver los pasos en la <Link to='/'>página de inicio</Link>. 
                    Recuerda que tus datos están 100% seguros, <b>no almacenamos tu información</b>.
                </div>
            </p>
            <br />

            {
                zipFile ?
                    <div>
                        {
                            isProcessedFile ?
                            <div>
                                <div className='fileNameContainer'>
                                    <FaFileZipper size={40} />&nbsp;
                                    <h4>
                                        {zipFile.name.length > 42 ? zipFile.name.slice(0, 20) + '...' + zipFile.name.slice(zipFile.name.length - 20, zipFile.name.length): zipFile.name}
                                    </h4>
                                </div>
                                <div className='fileNameContainerMobile'>
                                    <div className='fileNameContentMobile'>
                                        <FaFileZipper size={40} />&nbsp;
                                        <b>{zipFile.name.length > 35 ? zipFile.name.slice(0, 15) + '...' + zipFile.name.slice(zipFile.name.length - 15, zipFile.name.length): zipFile.name}</b>
                                    </div>
                                </div>
                                <div className="centerElements">
                                    Archivo procesado correctamente&nbsp;<FaRegCheckCircle color='green'/>
                                </div>
                                <br /><br />
                                <div className="centerElements">
                                    <button type="button" className="btn btn-primary" onClick={() => navigate('/analyzed-data')}>
                                        Ver los resultados
                                    </button> &nbsp;&nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={() => restart()}>Cargar otro archivo</button>
                                </div>
                            </div> :
                            <div>
                                <div className='row customizedSpinner'>
                                    <div className="spinner-border" style={{ width: '4rem', height: '4rem' }} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="row" style={{justifyContent: 'center'}}>
                                    Procesando datos
                                </div>
                            </div>
                            
                        }
                        
                    </div> :
                    <Dropzone
                        onDrop={onDrop}
                        onDropRejected={onDropRejected}
                        maxFiles={1}
                        accept={{ 'application/zip': ['.zip'] }}
                    >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                                {
                                    isDragActive ?
                                        <div className='customDropOnFile' {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <SlCloudUpload size={100} color='gray' />
                                            <br />
                                            <h4>Suelta el archivo aquí...</h4>
                                        </div> :
                                        <div className='customDrop' {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <SlCloudUpload size={100} color='gray' />
                                            <br />
                                            <h4 className='inputTextDesktop'>
                                                Arrastra y suelta el archivo aquí, o haz clic para seleccionarlo
                                            </h4>
                                            <h5 className='inputTextMobile'>
                                                Haz clic aquí para seleccionar el archivo, o arrástralo y suéltalo en esta área
                                            </h5>
                                        </div>
                                }
                            </section>
                        )}
                    </Dropzone>
            }
        </div>
    );
}