import { useEffect, useState } from 'react';
import styles from './home.module.css';
import step1 from '../../assets/step1-web.jpg';
import step2 from '../../assets/step2-web.jpg';
import step3 from '../../assets/step3-web.jpg';
import step4 from '../../assets/step4-web.jpg';
import step5 from '../../assets/step5-web.jpg';
import step6 from '../../assets/step6-web.jpg';
import step7 from '../../assets/step7-web.jpg';
import step8 from '../../assets/step8-web.jpg';
import step9 from '../../assets/step9-web.jpg';
import step10 from '../../assets/step10-web.jpg';
import step11 from '../../assets/step11-web.jpg';

import step1Mobile from '../../assets/step1-mobile.jpg';
import step2Mobile from '../../assets/step2-mobile.jpg';
import step3Mobile from '../../assets/step3-mobile.jpg';
import step4Mobile from '../../assets/step4-mobile.jpg';
import step5Mobile from '../../assets/step5-mobile.jpg';
import step6Mobile from '../../assets/step6-mobile.jpg';
import step7Mobile from '../../assets/step7-mobile.jpg';
import step8Mobile from '../../assets/step8-mobile.jpg';
import step9Mobile from '../../assets/step9-mobile.jpg';
import step10Mobile from '../../assets/step10-mobile.jpg';
import step11Mobile from '../../assets/step11-mobile.jpg';

import { useNavigate } from 'react-router';
import Modal from '../modal/modal';
import { IoIosInformationCircleOutline } from "react-icons/io";

export default function Home() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('web');
    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 768);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [imgPath, setImgPath] = useState<string>("");
    const [modaltitle, setModaltitle] = useState<string>("");

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    const handleImgPath = (path: string) => {
        setImgPath(path);
    }

    const handleModalTitle = (title: string) => {
        setModaltitle(title);
    }

    useEffect(() => {
        const handleResize = () => {
          setIsNarrowScreen(window.innerWidth < 768);
        };
    
        window.addEventListener("resize", handleResize);
    
        // Limpieza del evento cuando el componente se desmonta
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        isNarrowScreen ? setActiveTab('mobile') : setActiveTab('web');
    }, [isNarrowScreen]);

    return (
        <div className={styles.container1}>
            <Modal isOpen={isModalOpen} onClose={closeModal} imgPath={imgPath} title={modaltitle}/>
            <h2 style={{margin: '40px 0 40px 0'}}>¿Cómo saber quién no me sigue en instagram?</h2>
            <p className={styles.paragraph}>
                En esta web podrás conocer <b>quién no te sigue en instagram</b> así como las <b>solicitudes que no te han aceptado</b>, entre otras cosas más.
                El método que utilizaremos es 100% seguro;
                además de que <b>no almacenamos tus datos</b>, tampoco tendrás que preocuparte por perder tu cuenta
                como sucede con aplicaciones poco confiables.
                <br /><br />
                Instagram ofrece una opción para descargar un archivo, es necesario que lo descargues para que esta web lo procese.
                A continuación te mostramos cómo:
            </p>
            <p className={styles.adviceText}>
                <IoIosInformationCircleOutline/>&nbsp;Si necesitas ampliar una imagen, haz clic sobre ella.
            </p>
            <div className={`row ${styles.cardsContainer}`}>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step1} className={`card-img-top ${styles.image}`} alt='Paso 1' onClick={() => {openModal(); handleImgPath(step1); handleModalTitle("Paso 1");}}/>
                            ) : (
                                <img src={step1Mobile} className={`card-img-top ${styles.image}`} alt='Paso 1' onClick={() => {openModal(); handleImgPath(step1Mobile); handleModalTitle("Paso 1");}} />
                            )
                        }
                        
                        <div className="card-body">
                            <h5 className="card-title">Paso 1</h5>
                            {
                                activeTab === 'web' ? (
                                    <p className="card-text">
                                        Debes dirigirte al menú <b>Configuración</b> de instagram. Para acceder a él, 
                                        primero debes seleccionar el ícono de tres rayas <i>"Más"</i> y luego <i>"Configuración"</i>.
                                    </p>
                                ) : (
                                    <p>
                                        Ingresa al menú <b>Configuración y actividad</b> que se encuentra en tu perfil de la app de instagram.
                                    </p>
                                )
                            }
                            
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step2} className={`card-img-top ${styles.image}`} alt='Paso 2' onClick={() => {openModal(); handleImgPath(step2); handleModalTitle("Paso 2");}}/>
                            ) : (
                                <img src={step2Mobile} className={`card-img-top ${styles.image}`} alt='Paso 2' onClick={() => {openModal(); handleImgPath(step2Mobile); handleModalTitle("Paso 2");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 2</h5>
                            {
                                activeTab === 'web' ? (
                                    <p className="card-text">
                                        Dentro del menú <i>"Configuración"</i>, haz clic sobre el recuadro <b>Centro de cuentas</b>.
                                    </p>
                                ) : (
                                    <p>Selecciona la opción <b>Centro de cuentas</b>.</p>
                                )
                            }
                            
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step3} className={`card-img-top ${styles.image}`} alt='Paso 3' onClick={() => {openModal(); handleImgPath(step3); handleModalTitle("Paso 3");}}/>
                            ) : (
                                <img src={step3Mobile} className={`card-img-top ${styles.image}`}  alt='Paso 3' onClick={() => {openModal(); handleImgPath(step3Mobile); handleModalTitle("Paso 3");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 3</h5>
                            <p className="card-text">
                                Ingresa a <b>Tu información y permisos</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step4} className={`card-img-top ${styles.image}`} alt='Paso 4' onClick={() => {openModal(); handleImgPath(step4); handleModalTitle("Paso 4");}}/>
                            ) : (
                                <img src={step4Mobile} className={`card-img-top ${styles.image}`} alt='Paso 4' onClick={() => {openModal(); handleImgPath(step4Mobile); handleModalTitle("Paso 4");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 4</h5>
                            <p className="card-text">
                                Dentro del menú <i>"Tu información y permisos"</i>, debes seleccionar la opción <b>Descargar tu información</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step5} className={`card-img-top ${styles.image}`} alt='Paso 5' onClick={() => {openModal(); handleImgPath(step5); handleModalTitle("Paso 5");}}/>
                            ) : (
                                <img src={step5Mobile} className={`card-img-top ${styles.image}`} alt='Paso 5' onClick={() => {openModal(); handleImgPath(step5Mobile); handleModalTitle("Paso 5");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 5</h5>
                            <p className="card-text">
                                Selecciona la opción <b>Descargar o transferir información</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step6} className={`card-img-top ${styles.image}`} alt='Paso 6' onClick={() => {openModal(); handleImgPath(step6); handleModalTitle("Paso 6");}}/>
                            ) : (
                                <img src={step6Mobile} className={`card-img-top ${styles.image}`} alt='Paso 6' onClick={() => {openModal(); handleImgPath(step6Mobile); handleModalTitle("Paso 6");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 6</h5>
                            <p className="card-text">
                                Selecciona el perfil de instagram del cual necesitas descargar la información, luego presiona el botón <b>Siguiente</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step7} className={`card-img-top ${styles.image}`} alt='Paso 7' onClick={() => {openModal(); handleImgPath(step7); handleModalTitle("Paso 7");}}/>
                            ) : (
                                <img src={step7Mobile} className={`card-img-top ${styles.image}`} alt='Paso 7' onClick={() => {openModal(); handleImgPath(step7Mobile); handleModalTitle("Paso 7");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 7</h5>
                            <p className="card-text">
                                Luego debes seleccionar la opción <b>Parte de tu información</b>, ya que no se necesita descargar toda la información.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step8} className={`card-img-top ${styles.image}`} alt='Paso 8' onClick={() => {openModal(); handleImgPath(step8); handleModalTitle("Paso 8");}}/>
                            ) : (
                                <img src={step8Mobile} className={`card-img-top ${styles.image}`} alt='Paso 8' onClick={() => {openModal(); handleImgPath(step8Mobile); handleModalTitle("Paso 8");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 8</h5>
                            <p className="card-text">
                                Posteriormente, en el apartado de <i>"Conexiones"</i>, debes seleccionar la opción <b>Seguidores y seguidos</b>, 
                                y luego presionar el botón <b>Siguiente</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step9} className={`card-img-top ${styles.image}`} alt='Paso 9' onClick={() => {openModal(); handleImgPath(step9); handleModalTitle("Paso 9");}}/>
                            ) : (
                                <img src={step9Mobile} className={`card-img-top ${styles.image}`} alt='Paso 9' onClick={() => {openModal(); handleImgPath(step9Mobile); handleModalTitle("Paso 9");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 9</h5>
                            <p className="card-text">
                                Selecciona la opción <b>Descargar en dispositivo</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step10} className={`card-img-top ${styles.image}`} alt='Paso 10' onClick={() => {openModal(); handleImgPath(step10); handleModalTitle("Paso 10");}}/>
                            ) : (
                                <img src={step10Mobile} className={`card-img-top ${styles.image}`} alt='Paso 10' onClick={() => {openModal(); handleImgPath(step10Mobile); handleModalTitle("Paso 10");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 10</h5>
                            <p className="card-text">
                                En el apartado <i>"Intervalo de fechas"</i> debes seleccionar <b>Desde el principio</b>, y en el apartado <i>"Formato"</i> es
                                muy importante que selecciones <b>JSON</b>. Luego presiona el botón <b>Crear archivos</b>.
                            </p>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'web' ? 'active' : ''}`} onClick={() => handleTabClick('web')}>
                                    Instagram web
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${styles.customNavLink} ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
                                    Aplicación móvil
                                </a>
                            </li>
                        </ul>
                        {
                            activeTab === 'web' ? (
                                <img src={step11} className={`card-img-top ${styles.image}`} alt='Paso 11' onClick={() => {openModal(); handleImgPath(step11); handleModalTitle("Paso 11");}}/>
                            ) : (
                                <img src={step11Mobile} className={`card-img-top ${styles.image}`} alt='Paso 11' onClick={() => {openModal(); handleImgPath(step11Mobile); handleModalTitle("Paso 11");}} />
                            )
                        }
                        <div className="card-body">
                            <h5 className="card-title">Paso 11 - Listo! Descarga tu archivo</h5>
                            {
                                activeTab === 'web' ? (
                                    <>
                                        <p className="card-text">
                                            Luego del paso anterior, te llegará un correo en un lapso de 
                                            2 a 5 minutos, en el cual instagram te proporciona un enlace para que descargues tu archivo. Cuando le des clic al enlace, este te llevará a instagram, estando ahí, presiona el
                                            botón <b>Descargar</b> que se ve en la imagen.
                                        </p>
                                        <p>Una vez que hayas descargado el archivo, cárgalo en el siguiente apartado para que te mostremos qué cuentas no te siguen y muchas cosas más:</p>
                                    </>
                                ) : (
                                    <>
                                        <p className="card-text">
                                            Luego del paso anterior, te llegará un correo en un lapso de 
                                            2 a 5 minutos, en el cual instagram te proporciona un enlace para que descargues tu archivo. 
                                            Puedes iniciar sesión en tu navegador para descargarlo, o simplemente volver a dirigirte a la pantalla del paso 10, ya que ahí se
                                            habilitará el botón <b>Descargar</b> para que obtengas tu archivo.
                                        </p>
                                        <p>Una vez que hayas descargado el archivo, cárgalo en el siguiente apartado para que te mostremos qué cuentas no te siguen y muchas cosas más:</p>
                                    </>
                                )
                            }
                            
                            <button type="button" className="btn btn-primary" onClick={() => navigate('/data-capture')}>
                                Cargar archivo
                            </button>
                            <br /><br />
                            <p>
                                <IoIosInformationCircleOutline size={20}/> Si realizaste cambios en tu cuenta luego de descargar el archivo, es decir, dejaste de seguir a alguien, 
                                obtuviste más seguidores, etc., y quieres ver la información que te proporcionamos actualizada, necesitarás descargar un archivo nuevo.
                                En la pantalla del paso 10 se mostrará una lista con todos los archivos que has 
                                generado (a excepción de los que ya caducaron), el primero de la lista siempre será el más reciente.
                                Puedes generar todos los que quieras sin problemas!
                            </p>
                        </div>
                    </div>
            </div>
        </div>
    );
}