import "./modal.css";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    imgPath: string;
    title: string;
}

export default function Modal({ isOpen, onClose, imgPath, title }: ModalProps) {
    return (
        <>
            <div className={`modal fade ${isOpen ? "show d-block" : ""}`} tabIndex={-1} style={{ background: "rgba(0,0,0,0.5)" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <img src={imgPath} className="card-img-top modal-img" alt={title}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}