import styles from './sidebar.module.css'
import { NavLink } from 'react-router-dom';
import { IoHome } from "react-icons/io5";
import { FaFileZipper } from "react-icons/fa6";
import { BsDatabaseFillCheck } from "react-icons/bs";
import { useGlobalContext } from '../../global-context';

export default function Sidebar() {
    const {showSideBar} = useGlobalContext();
    let displayLabels = 'inherit';
    let displaySpaces = 'none';

    showSideBar ? displayLabels = 'inherit' : displayLabels = 'none';
    showSideBar ? displaySpaces = 'none' : displaySpaces = 'inherit';

    return (
        <div className={`${styles.sidebar}`} style={{width: '210px'}}>
            <div className={`row`}>
                <NavLink
                    to='/'
                    className={({ isActive }) =>
                        isActive ? `${styles.item} ${styles.active}` : styles.item
                    }
                    style={{padding: '0'}}
                    >
                    <div style={{padding: '10px 0 10px 15px', display: 'flex', alignItems: 'center'}}>
                        <IoHome size={20} />
                        <div>
                            <div style={{display: displaySpaces}}>&nbsp;</div>
                            <label style={{paddingLeft: '15px', cursor: 'pointer', display: displayLabels}}>
                                Inicio
                            </label>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="row">
                <NavLink
                    to='/data-capture'
                    className={({ isActive }) =>
                        isActive ? `${styles.item} ${styles.active}` : styles.item
                    }
                    style={{padding: '0'}}
                    >
                    <div style={{padding: '10px 0 10px 15px', display: 'flex', alignItems: 'center'}}>
                        <FaFileZipper size={20} />
                        <div>
                            <div style={{display: displaySpaces}}>&nbsp;</div>
                            <label style={{paddingLeft: '15px', cursor: 'pointer', display: displayLabels}}>
                                Cargar archivo zip
                            </label>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="row">
                <NavLink
                    to='/analyzed-data'
                    className={({ isActive }) =>
                        isActive ? `${styles.item} ${styles.active}` : styles.item
                    }
                    style={{padding: '0'}}
                    >
                    <div style={{padding: '10px 0 10px 15px', display: 'flex', alignItems: 'center'}}>
                        <BsDatabaseFillCheck size={20} />
                        <div>
                            <div style={{display: displaySpaces}}>&nbsp;</div>
                            <label style={{paddingLeft: '15px', cursor: 'pointer', display: displayLabels}}>
                                Datos analizados
                            </label>
                        </div>
                    </div>
                </NavLink>
            </div>
        </div>
    )
}