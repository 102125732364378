import './inside-footer.css'

export default function InsideFooter() {
    return (
        <div className="insideFooterContainer" style={{marginTop: '300px'}}>
            <div className="row borderRow"></div>
            <div className="row footerContentRow">
                <div className="col">
                    <ul>
                        <li>¿Cómo saber quién no me sigue en instagram?</li>
                        <li>¿Cómo saber quienes no me han aceptado la solicitud en instagram?</li>
                        <li>¿Cómo saber a quién dejé de seguir recientemente en instagram?</li>
                        <li>¿Cómo ver los perfiles sugeridos de instagram que he descartado?</li>
                        <li>¿Cómo ver la fecha exacta en la que comencé a seguir a alguien en instagram?</li>
                        <li>¿Cómo ver la fecha exacta en la que alguien me comenzó a seguir en instagram?</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>
                        <li>¿Cómo saber la fecha exacta en la que mandé una solicitud de seguimiento en instagram?</li>
                        <li>¿Cómo saber en qué fecha dejé de seguir a alguien en instagram?</li>
                        <li>¿Cómo saber en qué fecha exacta descarté un perfil sugerido en instagram?</li>
                    </ul>
                </div>
            </div>
            <div className="row" style={{justifyContent: 'center', paddingTop: '20px'}}>
                © 2024 FoLo. Todos los derechos reservados.
            </div>
        </div>
    )
}