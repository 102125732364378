import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/home/home';
import DataCapture from './Components/data-capture/data-capture';
import AnalyzedData from './Components/analyzed-data/analyzed-data';
import Navbar from './Components/navbar/navbar';
import Sidebar from './Components/sidebar/sidebar';
import InsideFooter from './Components/inside-footer/inside-footer';
import { useGlobalContext } from './global-context';
import Footer from './Components/footer/footer';
import { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

function App() {
  const {showSideBar, index, setIndex, scrollPositions, setScrollPositions} = useGlobalContext();
  let sidebarWidth = '210px';
  // const scrollPositions = useRef<Record<string, number>>({}); // Diccionario para guardar las posiciones del scroll
  const location = useLocation(); // Obtener la ubicación actual
  const { pathname } = useLocation();

  showSideBar ? sidebarWidth = '210px' : sidebarWidth = '60px';

  useEffect(() => {
    const container = document.querySelector(".app-body"); // Identifica el contenedor del scroll

    if (container) {
      container.scrollTo(0, scrollPositions[pathname]);

    }

    return () => {
      // Guardar la posición actual del scroll al salir de una ruta
      const container = document.querySelector(".app-body");
      if (container) {
        const obj = {...scrollPositions};
        obj[pathname] = container.scrollTop;
        console.log(obj);
        setScrollPositions(obj);
      }
    };
  }, [location.pathname]); // Ejecutar cuando cambie la ruta

  // useEffect(() => {
  //   const container = document.querySelector(".app-body"); // Identifica el contenedor
  //   // let index = 0;
  //   if (container) {
  //     setIndex(index + 1);
  //     container.scrollTo(0, 0); // Restaura el scroll al tope
  //     console.log(index)
  //     console.log(`Scroll reset en la ruta: ${pathname}`);
  //   }
  // }, [pathname]);

  return (
    <div>
      <div className='nav-body'>
        <Navbar />
      </div>
      <div className="row">
        <div className="side-body" style={{ width: sidebarWidth }}>
          <Sidebar />
        </div>
        <div className="col app-body-col">
          <div className='row app-body' id="appContainer">
            <div className="row" style={{ padding: '0' }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/data-capture" element={<DataCapture />} />
                <Route path="/analyzed-data" element={<AnalyzedData />} />
              </Routes>
            </div>
            <div className="row" style={{ padding: '0' }}>
              <InsideFooter />
            </div>
          </div>
          <div className="row footer-row">
            <Footer/>
          </div>
        </div>
      </div>
    </div>
      
  );
}

export default App;
