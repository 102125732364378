import "./footer.css";
import { IoHome } from "react-icons/io5";
import { FaFileZipper } from "react-icons/fa6";
import { BsDatabaseFillCheck } from "react-icons/bs";
import { NavLink } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="footerContainer" style={{padding: 0}}>
            <div className="row">
                <div className="col" style={{padding: 0}}>
                    <NavLink 
                        to={'/'}
                        className={({ isActive }) =>
                            isActive ? 'footerItem activeItem' : 'footerItem'
                        }
                        >
                        <IoHome size={20}/>
                        <label>Inicio</label>
                    </NavLink>
                </div>
                <div className="col" style={{padding: 0}}>
                    <NavLink 
                        to={'/data-capture'}
                        className={({ isActive }) =>
                            isActive ? 'footerItem activeItem' : 'footerItem'
                        }
                        >
                        <FaFileZipper size={20}/>
                        <label>Cargar archivo</label>
                    </NavLink>
                </div>
                <div className="col" style={{padding: 0}}>
                    <NavLink 
                        to={'/analyzed-data'}
                        className={({ isActive }) =>
                            isActive ? 'footerItem activeItem' : 'footerItem'
                        }
                        >
                        <BsDatabaseFillCheck size={20}/>
                        <label>Datos analizados</label>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}