import { useGlobalContext } from '../../global-context';
import styles from './navbar.module.css';
import { CgDetailsMore } from "react-icons/cg";

export default function Navbar() {
    const {showSideBar, setShowSideBar} = useGlobalContext();

    const handleShowSideBar = () => {
        setShowSideBar(!showSideBar);
    }

    return (
        <div className={styles.navbarContainer}>
            <div className="col">
                <CgDetailsMore size={32} className={styles.moreIcon} onClick={handleShowSideBar}/>
            </div>
            <div className={`col ${styles.centerCol}`}>
                <h2>FoLo</h2>
                <label className={styles.label}>Analy<b>zer</b></label>
            </div>
            <div className="col"></div>
        </div>
    )
}