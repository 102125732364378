import React from "react";

const Unfollowers = (data: any) => {
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '45px' }}>#</th>
                        <th scope="col">Nombre de usuario</th>
                        <th scope="col">Enlace al perfil</th>
                    </tr>
                </thead>
                <tbody className='custom-table-body'>
                    {
                        data.data.map((item: any, index: number) => (
                            <tr>
                                <td style={{ width: '45px' }}>{index + 1}</td>
                                <td>{item.username}</td>
                                <td><a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a></td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    );
}

const UnfollowersMobile = (data: any) => {
    return (
        <>
            <table className='table'>
                <tbody>
                    {
                        data.data.map((item: any, index: any) => (
                            <tr>
                                <td style={{ width: '45px' }}>{index + 1}</td>
                                <td>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">{item.username}</a>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    );
}

const InfoTableDesk_ = (data: any) => {
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '45px' }}>#</th>
                        <th scope="col">Nombre de usuario</th>
                        <th scope="col">Fecha y hora</th>
                        <th scope="col">Enlace al perfil</th>
                    </tr>
                </thead>
                <tbody className='custom-table-body'>
                    {
                        data.data.map((item: any, index: any) => (
                            <tr>
                                <td style={{ width: '45px' }}>{index + 1}</td>
                                <td>{item.username}</td>
                                <td>{item.date}</td>
                                <td><a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a></td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    );
}

const InfoTableMobile_ = (data: any) => {
    return (
        <>
            <table className='table'>
                <tbody>
                    {
                        data.data.map((item: any, index: any) => (
                            <tr>
                                <td style={{ width: '45px' }}>{index + 1}</td>
                                <td>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">{item.username}</a>
                                    <br />
                                    {item.date}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    );
}

export const UnfollowersTableDesk = React.memo(Unfollowers);
export const UnfollowersMobileTable = React.memo(UnfollowersMobile);
export const InfoTableDesk = React.memo(InfoTableDesk_);
export const InfoTableMobile = React.memo(InfoTableMobile_);