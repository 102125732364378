import './analyzed-data.css'
import { useGlobalContext } from '../../global-context'
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { InfoTableDesk, InfoTableMobile, UnfollowersMobileTable, UnfollowersTableDesk } from './lists';
import { IoCloseSharp } from "react-icons/io5";

export default function AnalyzedData() {
    const {variableData} = useGlobalContext();

    let showFollowing: boolean = false;
    let showFollowers: boolean = false;
    let showUnfollowers: boolean = false;
    let showPendingFollowRequests: boolean = false;
    let showRecentlyUnfollowedAccounts: boolean = false;
    let showRemovedSuggestions: boolean = false;

    const [searchUnfollowers, setSearchUnfollowers] = useState("");
    const [searchPendingFollowRequests, setSearchPendingFollowRequests] = useState("");
    const [searchRecentlyUnfollowedAccounts, setSearchRecentlyUnfollowedAccounts] = useState("");
    const [searchRemovedSuggestions, setSearchRemovedSuggestions] = useState("");
    const [searchFollowing, setSearchFollowing] = useState("");
    const [searchFollowers, setSearchFollowers] = useState("");

    const [unfollowersData, setUnfollowersData] = useState(variableData?.unfollowers);
    const [pendingFollowRequestsData, setPendingFollowRequestsData] = useState(variableData?.pendingFollowRequests);
    const [recentlyUnfollowedAccountsData, setRecentlyUnfollowedAccountsData] = useState(variableData?.recentlyUnfollowedAccounts);
    const [removedSuggestionsData, setRemovedSuggestionsData] = useState(variableData?.removedSuggestions);
    const [followingData, setFollowingData] = useState(variableData?.following);
    const [followersData, setFollowersData] = useState(variableData?.followers);

    if (typeof variableData != 'string') {
        if (variableData.unfollowers.length > 0) {
            showUnfollowers = true;
        }
        if (variableData.pendingFollowRequests.length > 0) {
            showPendingFollowRequests = true;
        }
        if (variableData.recentlyUnfollowedAccounts.length > 0) {
            showRecentlyUnfollowedAccounts = true;
        }
        if (variableData.removedSuggestions.length > 0) {
            showRemovedSuggestions = true;
        }
        if (variableData.followers.length > 0) {
            showFollowers = true;
        }
        if (variableData.following.length > 0) {
            showFollowing = true;
        }
    }

    const debouncedFilter = useCallback(
        debounce((list: any[], searchValue: string, setFilteredList: React.Dispatch<React.SetStateAction<any[]>>) => {
            const filtered = list.filter((item) =>
                item.username.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredList(filtered);
        }, 300), // 300ms de espera
        []
    );

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        list: any[],
        setFilteredList: React.Dispatch<React.SetStateAction<any[]>>,
        setSearchTerm: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedFilter(list, value, setFilteredList);
    };

    const handleInputClear = (
        setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
        setFilteredList: React.Dispatch<React.SetStateAction<any[]>>,
        list: any
    ) => {
        setSearchTerm("");
        setFilteredList(list);
    }
    

    return (
        <div className='analizedDataContainer'>
            <h2 className='dataTitle'>
                Datos analizados
            </h2>

            {
                typeof variableData != 'string' ?
                (
                <div>
                    <div>Despliega cada uno de los módulos para ver el contenido.</div>
                    <br />
                    <br />
    
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item" style={{position: 'sticky'}}>
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    <h5>Cuentas que no te siguen de vuelta ({variableData.unfollowers.length})</h5>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    {
                                        showUnfollowers ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Buscar"
                                                        aria-describedby="basic-addon2"
                                                        value={searchUnfollowers}
                                                        onChange={(event) => handleInputChange(event, variableData.unfollowers, setUnfollowersData, setSearchUnfollowers)}
                                                    />
                                                    {searchUnfollowers && (
                                                        <button className='closeButton' onClick={() => handleInputClear(setSearchUnfollowers, setUnfollowersData, variableData.unfollowers)}>
                                                            <IoCloseSharp />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className='tableContainerDesk'>
                                                    <UnfollowersTableDesk data = {unfollowersData} />
                                                </div>
                                                <div className='tableContainerMobile'>
                                                    <UnfollowersMobileTable data = {unfollowersData} />
                                                </div>
                                            </div>
                                            
                                        ) : (
                                            <label>Vaya! Parece que no hay nada por aquí.</label>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" style={{position: 'sticky'}}>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                    <h5>Solicitudes de seguimiento que no te han aceptado ({variableData.pendingFollowRequests.length})</h5>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    {
                                        showPendingFollowRequests ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Buscar"
                                                        aria-describedby="basic-addon2"
                                                        value={searchPendingFollowRequests}
                                                        onChange={(event) => handleInputChange(event, variableData.pendingFollowRequests, setPendingFollowRequestsData, setSearchPendingFollowRequests)}
                                                    />
                                                    {searchPendingFollowRequests && (
                                                        <button className='closeButton' onClick={() => handleInputClear(setSearchPendingFollowRequests, setPendingFollowRequestsData, variableData.pendingFollowRequests)}>
                                                            <IoCloseSharp />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className='tableContainerDesk'>
                                                    <InfoTableDesk data = {pendingFollowRequestsData}/>
                                                </div>
                                                <div className='tableContainerMobile'>
                                                    <InfoTableMobile data = {pendingFollowRequestsData}/>
                                                </div>
                                            </div>
                                            
                                        ) : (
                                            <label>Vaya! Parece que no hay nada por aquí.</label>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" style={{position: 'sticky'}}>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    <h5>Cuentas que dejaste de seguir recientemente ({variableData.recentlyUnfollowedAccounts.length})</h5>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    {
                                        showRecentlyUnfollowedAccounts ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Buscar"
                                                        aria-describedby="basic-addon2"
                                                        value={searchRecentlyUnfollowedAccounts}
                                                        onChange={(event) => handleInputChange(event, variableData.recentlyUnfollowedAccounts, setRecentlyUnfollowedAccountsData, setSearchRecentlyUnfollowedAccounts)}
                                                    />
                                                    {searchRecentlyUnfollowedAccounts && (
                                                        <button className='closeButton' onClick={() => handleInputClear(setSearchRecentlyUnfollowedAccounts, setRecentlyUnfollowedAccountsData, variableData.recentlyUnfollowedAccounts)}>
                                                            <IoCloseSharp />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className='tableContainerDesk'>
                                                    <InfoTableDesk data = {recentlyUnfollowedAccountsData}/>
                                                </div>
                                                <div className='tableContainerMobile'>
                                                    <InfoTableMobile data = {recentlyUnfollowedAccountsData}/>
                                                </div>
                                            </div>
                                                                                        
                                        ) : (
                                            <label>Vaya! Parece que no hay nada por aquí.</label>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" style={{position: 'sticky'}}>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                    <h5>Perfiles sugeridos que has descartado ({variableData.removedSuggestions.length})</h5>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    {
                                        showRemovedSuggestions ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Buscar"
                                                        aria-describedby="basic-addon2"
                                                        value={searchRemovedSuggestions}
                                                        onChange={(event) => handleInputChange(event, variableData.removedSuggestions, setRemovedSuggestionsData, setSearchRemovedSuggestions)}
                                                    />
                                                    {searchRemovedSuggestions && (
                                                        <button className='closeButton' onClick={() => handleInputClear(setSearchRemovedSuggestions, setRemovedSuggestionsData, variableData.removedSuggestions)}>
                                                            <IoCloseSharp />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className='tableContainerDesk'>
                                                    <InfoTableDesk data = {removedSuggestionsData}/>
                                                </div>
                                                <div className='tableContainerMobile'>
                                                    <InfoTableMobile data = {removedSuggestionsData}/>
                                                </div>
                                            </div>                                    
                                        ) : (
                                            <label>Vaya! Parece que no hay nada por aquí.</label>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" style={{position: 'sticky'}}>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                    <h5>Seguidores ({variableData.followers.length})</h5>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    {
                                        showFollowers ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Buscar"
                                                        aria-describedby="basic-addon2"
                                                        value={searchFollowers}
                                                        onChange={(event) => handleInputChange(event, variableData.followers, setFollowersData, setSearchFollowers)}
                                                    />
                                                    {searchFollowers && (
                                                        <button className='closeButton' onClick={() => handleInputClear(setSearchFollowers, setFollowersData, variableData.followers)}>
                                                            <IoCloseSharp />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className='tableContainerDesk'>
                                                    <InfoTableDesk data = {followersData}/>
                                                </div>
                                                <div className='tableContainerMobile'>
                                                    <InfoTableMobile data = {followersData}/>
                                                </div>
                                            </div>
                                                                                        
                                        ) : (
                                            <label>Vaya! Parece que no hay nada por aquí.</label>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" style={{position: 'sticky'}}>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                    <h5>Seguidos ({variableData.following.length})</h5>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                    {
                                        showFollowing ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Buscar"
                                                        aria-describedby="basic-addon2"
                                                        value={searchFollowing}
                                                        onChange={(event) => handleInputChange(event, variableData.following, setFollowingData, setSearchFollowing)}
                                                    />
                                                    {searchFollowing && (
                                                        <button className='closeButton' onClick={() => handleInputClear(setSearchFollowing, setFollowingData, variableData.following)}>
                                                            <IoCloseSharp />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className='tableContainerDesk'>
                                                    <InfoTableDesk data = {followingData}/>
                                                </div>
                                                <div className='tableContainerMobile'>
                                                    <InfoTableMobile data = {followingData}/>
                                                </div>
                                            </div>                             
                                        ) : (
                                            <label>Vaya! Parece que no hay nada por aquí.</label>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
                ) :
                (
                    <div>
                        Aún no has cargado el archivo zip, una vez que lo cargues <Link to={'/data-capture'}>aquí</Link>, podrás ver en esta página lo siguiente:
                        <br /> <br />
                        <ul>
                            <li>Cuentas que no te siguen de vuelta</li>
                            <li>Cuentas que no te han aceptado la solicitud, y la fecha en la que enviaste la solicitud</li>
                            <li>Cuentas que has dejado de seguir recientemente, y la fecha en la que las dejaste de seguir</li>
                            <li>Perfiles sugeridos que has descartado, y la fecha en la que descartaste cada perfil</li>
                            <li>Cuentas que sigues, y la fecha en la que las comenzaste a seguir</li>
                            <li>Cuentas que te siguen, y la fecha en la que te comenzaron a seguir</li>
                        </ul>
                    </div>
                )
            }

            
        </div>
    )
}