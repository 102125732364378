import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define el tipo para el contexto
interface GlobalContextType {
    variableData: any;
    setVariableData: (value: any) => void;
    zipFile: File | null;
    setZipFile: (value: File | null) => void;
    isProcessedFile: boolean;
    setIsProcessedFile: (value: boolean) => void;
    showSideBar: boolean;
    setShowSideBar: (value: boolean) => void;
    index: number;
    setIndex: (value: number) => void;
    scrollPositions: any;
    setScrollPositions: (value: any) => void;

}

// Crea el contexto
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

// Proveedor del contexto
interface GlobalProviderProps {
    children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [variableData, setVariableData] = useState<string>('Valor inicial');
    const [zipFile, setZipFile] = useState<File | null>(null);
    const [isProcessedFile, setIsProcessedFile] = useState<boolean>(false);
    const [showSideBar, setShowSideBar] = useState<boolean>(true);
    const [index, setIndex] = useState<number>(0);
    const [scrollPositions, setScrollPositions] = useState<any>({"/": 0, "/data-capture": 0, "/analyzed-data": 0});

    return (
        <GlobalContext.Provider value={
            { 
                variableData, 
                setVariableData, 
                zipFile, 
                setZipFile, 
                isProcessedFile, 
                setIsProcessedFile,
                showSideBar,
                setShowSideBar,
                index,
                setIndex,
                scrollPositions,
                setScrollPositions
            }}>
            {children}
        </GlobalContext.Provider>
    );
};

// Hook para usar el contexto
export const useGlobalContext = (): GlobalContextType => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext debe ser usado dentro de un GlobalProvider');
    }
    return context;
};
